import { Link } from 'gatsby';
import React, {useEffect}  from 'react';
import Searcher from '../search'
import { connect } from 'react-redux';
import { getDevelopmentsAction } from '../../redux/developmentsDucks';
import {getTotalListFilters} from '../../redux/propertiesDucks'
import Loading from '../Loading'

//Helpers
import { getLocation, getLastFeatured } from '../../helpers/helper.developments';
import { getCover } from '../../helpers/helper.rendering';
import { graphql, useStaticQuery } from 'gatsby';

const Home_Main = ({developments,loading,api_key,dispatch}) =>  {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              slogan
              main_image
          }
    }`)

    useEffect(() => {

        if(api_key && !developments?.data?.objects){
            dispatch(getDevelopmentsAction()) // Get all developments
        }
    },[api_key])

    const makeLink = (development) => {
        if(development){
            return "/emprendimientos/" + development.id 
        }
    }

    return !loading ? (
        developments?.data?.objects?.length && getLastFeatured(developments.data.objects) ? (
        <div id="home-main" class="banner-main" style={{backgroundImage:'url(' + (developments.data ? getCover(getLastFeatured(developments.data.objects).photos)?.original : realEstate?.main_image) + ")"}} >
            <div class="item d-flex justify-content-center text-center align-items-center">
                <div class="opacity"></div>
                <div class="container" style={{zIndex:"1"}}>
                    <div class="row align-items-center text-center justify-content-center">
                        <div class="col-12 pb-5">
                            <h1>{developments.data && getLastFeatured(developments.data.objects).name}</h1>
                            <p>
                                {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[0]}
                                <br />
                                {developments.data && getLocation(getLastFeatured(developments.data.objects),true)[1]}
                            </p> 
                            <a href={developments.data && makeLink(getLastFeatured(developments.data.objects))} target="_blank" class="btn btn-blue mb-5">DESCUBRILO</a>
                        </div>
                    </div>
                </div>
            </div>
            <Searcher />
        </div>
        ):
        <div id="home-main" class="banner-main" style={{backgroundImage:'url(' + realEstate?.main_image + ")"}} >
            <div class="item d-flex justify-content-center text-center align-items-center">
                <div class="opacity"></div>
                <div class="container" style={{zIndex:"1"}}>
                    <div class="row align-items-center text-center justify-content-center">
                        <div class="col-12 pb-5">
                             <h1>
                                {realEstate?.slogan}
                            </h1> 
                        </div>
                    </div>
                </div>
            </div>
            <Searcher />
        </div>
    ):
    <Loading absolute={true} />
}

export default connect(state => ({
    developments: state.developments.developments,
    loading: state.developments.loading,
    api_key: state.settings.keys.tokko
}),null)(Home_Main);