import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';


const About = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about {
                            title
                            paragraph
                            image
                            buttons {
                              link
                              value
                            }
                            items {
                              paragraph
                              value
                            }
                          }
                        }
                        about {
                            image
                        }
                    }
                }
    }`)

    return realEstate.sections && (
        <section class="about-us pt-5 mt-5" style={{backgroundImage:"url(" + realEstate.sections.about.image + ""}}>
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                <div class="row">
                    <div class="col-lg-2">
                        <p class="section-name">{realEstate.sections.home.about.title}</p>
                    </div>
                    <div class="col-lg-10">
                        <h2>{realEstate.sections.home.about.paragraph.split(".")[0]}.</h2>
                        {/* <h2>SOMOS UN EQUIPO PROFESIONAL, MULTIDISCIPLINARIO,<br class="d-none d-lg-block" /> EMPÁTICO Y RESOLUTIVO EN EL MUNDO DEL REAL ESTATE.</h2> */}
                    </div>
                    <div class="col-10 mt-lg-5 offset-lg-2">
                        <Link to={realEstate.sections.home.about.buttons[0].link} class="btn btn-white">{realEstate.sections.home.about.buttons[0].value}</Link>
                    </div>
                    {realEstate.sections.home.about.items.length > 1 &&
                        <div class="col-lg-10 offset-lg-2 pt-5 mt-5">
                            <div class="row py-lg-5">
                                {realEstate.sections.home.about.items.map((item, index) => (
                                    // index > 1 ?
                                        index % 2 === 0 ?
                                        <div class="col-lg-6 pr-lg-4">
                                            <div class="item">
                                                <div class="row no-gutters">
                                                    <div class="col-6 col-lg-6 text-end">
                                                        <h1 class="hyper d-flex justify-content-end">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span class="counter-value one">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h1>
                                                    </div>
                                                    <div class="col-6 col-lg-6 px-3">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div class="col-lg-6">
                                            <div class="item" id="counter">
                                                <div class="row no-gutters">
                                                    <div class="col-6 col-lg-7 text-end">
                                                        <h1 class="hyper d-flex justify-content-end">+
                                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                                {({ isVisible }) => (
                                                                <span class="counter-value one">
                                                                    {isVisible ? <CountUp end={item.value} /> : null}
                                                                </span>
                                                                )}
                                                            </VisibilitySensor>
                                                        </h1>
                                                    </div>
                                                    <div class="col-6 col-lg-5 px-3">
                                                        <p>{item.paragraph}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                ))}
                            </div>
                        </div>
                }
                </div>

            </div>
        </section>
    )
}

export default About